import axios from "./index";

class StudentMeasures {

    static get = (measureId, classroomId) => {
        return axios.get(`${base}/${measureId}/${classroomId}`);
    };

    static getStudentReport = (measureId, classroomId, studentId) => {
        return axios.get(`${base}/report/export/${measureId}/${classroomId}/${studentId}`, {responseType: 'arraybuffer'});
    };

    static getClassroomReport = (measureId, classroomId) => {
        return axios.get(`${base}/report/export/${measureId}/${classroomId}`, {responseType: 'arraybuffer'});
    };

    static saveAll = (data) => {
        return axios.post(`${base}/list`, data);
    };
}

let base = "/api/student-measures";

export default StudentMeasures;
