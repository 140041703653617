import React, { useRef } from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

function PdfBuilder() {

    const createTablePdf = () => {
        const title="SPORCU KARNESİ";
        const watermark="Softist Anthropometry";
        const headerContent="User Data Report - 2024";
        const footerContent="Softist Anthropometry © 2024 | http://www.softist.com.tr";

        const columnHeaders=["Name", "Age", "Country"];
        const dataList = [
            { Name: "Alice", Age: 25, Country: "USA" },
            { Name: "Bob", Age: 30, Country: "Canada" },
            { Name: "Charlie", Age: 35, Country: "UK" },
        ];

        const documentDefinition = {
            watermark: { text: watermark, color: "gray", opacity: 0.1, bold: true },
            header: { text: headerContent, style: "header" },
            pageOrientation: 'landscape',
            footer: (currentPage, pageCount) => ({
                text: `Sayfa  ${currentPage} / ${pageCount}\n${footerContent}`,
                alignment: "center"
            }),
            content: [
                { text: title, style: "title" , color:"red"},
                {
                    table: {
                        headerRows: 1,
                        widths: columnHeaders.map(() => "*"),
                        body: [
                            columnHeaders,
                            ...dataList.map((row) => columnHeaders.map((header) => row[header])),
                        ],
                    },
                },
            ],
            styles: {
                header: { fontSize: 12, bold: true, margin: [0, 10, 0, 10] },
                title: { fontSize: 18, bold: true, alignment: "center", margin: [0, 0, 0, 20] },
            },
        };

        pdfMake.createPdf(documentDefinition).download('GeneratedPDF.pdf');
    };

    return (
        <div>
            <br />
            <button onClick={createTablePdf}>Generate PDF</button>
        </div>
    );
}

export default PdfBuilder;